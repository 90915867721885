/* eslint-disable no-template-curly-in-string */
import superagent from 'superagent';

// courseUnitId

const getCourseUnitId = `query getCourseUnitId($course_unit_id: String!) {
  ILPOCourseUnit (
    where: {
      id: { _eq: $course_unit_id }
    }
  ) {
    id
    code
    nameFi
    startDate
    endDate
  }
}`;

const searchCourseUnitId = `
query withSearchTerm($search_term: String!, $offset: Int!) {
  ILPOCourseUnit (
    limit: 10,
    order_by: {code: asc},
    offset: $offset,
    where: {
      _and: [
        { documentState: { _nin: "DELETED" }},
        { _or: [
          { code: {_ilike: $search_term }},
          { id: {_ilike: $search_term }},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    code
    nameFi
    startDate
    endDate
  }
}
query withoutSearchTerm($search_term: String!, $offset: Int!) {
  ILPOCourseUnit (
    limit: 10,
    order_by: {code: asc},
    offset: $offset,
    where: { documentState: { _nin: "DELETED" }}
  ) {
    id
    code
    nameFi
    startDate
    endDate
  }
}
`;

export const sisuGetCourseUnitIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getCourseUnitId,
    variables: { course_unit_id: value },
  };
  return genericQuery(
    payload,
    '${id}: ${code} ${nameFi} (${startDate} - ${endDate})',
  );
};

export const sisuSearchCourseUnitIdQuery = (value, offset, fieldDeps) => {
  const searchTerm = value || '';
  const payload = {
    query: searchCourseUnitId,
    variables: { search_term: '%' + value + '%', offset: offset },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(
    payload,
    '${id}: ${code} ${nameFi} (${startDate} - ${endDate})',
  );
};

// courseUnitRealisationId

const getCourseUnitRealisationId = `query getCourseUnitRealisationId($course_unit_realisation_id: String!) {
  ILPOCourseUnitRealisation (
    where: {
      id: { _eq: $course_unit_realisation_id }
    }
  ) {
    id
    nameFi
    startDate
    endDate
  }
}`;

const searchCourseUnitRealisationId = `
query withSearchTerm($course_unit_id: _text!, $search_term: String!, $offset: Int!) {
  ILPOCourseUnitRealisation (
    limit: 10,
    offset: $offset,
    where: {
      _and: [
        { courseUnitIds: {_eq: $course_unit_id }},
        {_or: [
          { id: {_ilike: $search_term}},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    nameFi
    startDate
    endDate
  }
}
query withoutSearchTerm($course_unit_id: _text!, $search_term: String!, $offset: Int!) {
  ILPOCourseUnitRealisation (
    limit: 10,
    offset: $offset,
    where: { courseUnitIds: {_eq: $course_unit_id }}
  ) {
    id
    nameFi
    startDate
    endDate
  }
}
`;

export const sisuGetCourseUnitRealisationIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getCourseUnitRealisationId,
    variables: { course_unit_realisation_id: value },
  };
  return genericQuery(payload, '${id}: ${nameFi} (${startDate} - ${endDate})');
};

export const sisuSearchCourseUnitRealisationIdQuery = (
  value,
  offset,
  fieldDeps,
) => {
  const searchTerm = value || '';
  const courseUnitId = fieldDeps['ILPOCourseUnit'] || '';
  const payload = {
    query: searchCourseUnitRealisationId,
    variables: {
      course_unit_id: courseUnitId,
      search_term: '%' + value + '%',
      offset: offset,
    },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(payload, '${id}: ${nameFi} (${startDate} - ${endDate})');
};

// assessmentItemId

const getAssessmentItemId = `query getAssessmentItemId($assessment_item_id: String!) {
  ILPOAssessmentItem (
    where: {
      id: { _eq: $assessment_item_id }
    }
  ) {
    id
    nameFi
  }
}`;

const searchAssessmentItemId = `
query withSearchTerm($course_unit_realisation_id: _text!, $search_term: String!, $offset: Int!) {
  ILPOAssessmentItem (
    limit: 10,
    offset: $offset,
    where: {
      _and: [
        { courseUnitRealisationIds: { _eq: $course_unit_realisation_id }},
        {_or: [
          { id: {_ilike: $search_term }},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    nameFi
  }
}
query withoutSearchTerm($course_unit_realisation_id: _text!, $search_term: String!, $offset: Int!) {
  ILPOAssessmentItem (
    limit: 10,
    offset: $offset,
    where: { courseUnitRealisationIds: { _eq: $course_unit_realisation_id }}
  ) {
    id
    nameFi
  }
}
`;

export const sisuGetAssessmentItemIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getAssessmentItemId,
    variables: { assessment_item_id: value },
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

export const sisuSearchAssessmentItemIdQuery = (value, offset, fieldDeps) => {
  const searchTerm = value || '';
  const courseUnitRealisationId = fieldDeps['ILPOCourseUnitRealisation'] || '';
  const payload = {
    query: searchAssessmentItemId,
    variables: {
      course_unit_realisation_id: courseUnitRealisationId,
      search_term: '%' + value + '%',
      offset: offset,
    },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

// educationId

const getEducationId = `query getEducationId($education_id: String!) {
  ILPOEducation (
    where: {
      id: { _eq: $education_id }
    }
  ) {
    id
    nameFi
  }
}`;

const searchEducationId = `
query withSearchTerm($search_term: String!, $offset: Int!) {
  ILPOEducation (
    limit: 10,
    offset: $offset,
    where: {
      _and: [
        { documentState: {_nin: "DELETED" }},
        {_or: [
          { id: {_ilike: $search_term }},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    nameFi
  }
}
query withoutSearchTerm($search_term: String!, $offset: Int!) {
  ILPOEducation (
    limit: 10,
    offset: $offset,
    where: { documentState: {_nin: "DELETED" }}
  ) {
    id
    nameFi
  }
}
`;

export const sisuGetEducationIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getEducationId,
    variables: { education_id: value },
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

export const sisuSearchEducationIdQuery = (value, offset, fieldDeps) => {
  const searchTerm = value || '';
  const payload = {
    query: searchEducationId,
    variables: { search_term: '%' + value + '%', offset: offset },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

// studySubgroupId

const getStudySubgroupId = `query getStudySubgroupId($study_subgroup_id: String!) {
  ILPOStudySubgroup (
    where: {
      id: { _eq: $study_subgroup_id }
    }
  ) {
    id
    nameFi
  }
}`;

const searchStudySubgroupId = `
query withSearchTerm($course_unit_realisation_id: String!, $search_term: String!, $offset: Int!) {
  ILPOStudySubgroup(
    limit: 10,
    offset: $offset,
    where: {
      _and: [
        { courseUnitRealisationId: { _eq: $course_unit_realisation_id }},
        {_or: [
          { id: {_ilike: $search_term }},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    nameFi
  }
}
query withoutSearchTerm($course_unit_realisation_id: String!, $search_term: String!, $offset: Int!) {
  ILPOStudySubgroup(
    limit: 10,
    offset: $offset,
    where: { courseUnitRealisationId: { _eq: $course_unit_realisation_id }}
  ) {
    id
    nameFi
  }
}
`;

export const sisuGetStudySubgroupIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getStudySubgroupId,
    variables: { study_subgroup_id: value },
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

export const sisuSearchStudySubgroupIdQuery = (value, offset, fieldDeps) => {
  const searchTerm = value || '';
  const courseUnitRealisationId = fieldDeps['ILPOCourseUnitRealisation'] || '';
  const payload = {
    query: searchStudySubgroupId,
    variables: {
      course_unit_realisation_id: courseUnitRealisationId,
      search_term: '%' + value + '%',
      offset: offset,
    },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

// moduleId

const getModuleId = `query getModuleId($module_id: String!) {
  ILPOModule (
    where: {
      id: { _eq: $module_id }
    }
  ) {
    id
    nameFi
  }
}`;

const searchModuleId = `
query withSearchTerm($search_term: String!, $offset: Int!) {
  ILPOModule (
    limit: 10,
    order_by: {nameFi: asc},
    offset: $offset,
    where: {
      _and: [
        { documentState: { _nin: "DELETED" }},
        { _or: [
          { id: {_ilike: $search_term }},
          { nameFi: {_ilike: $search_term }},
          { nameEn: {_ilike: $search_term }}
        ]}
      ]
    }
  ) {
    id
    nameFi
  }
}
query withoutSearchTerm($search_term: String!, $offset: Int!) {
  ILPOModule (
    limit: 10,
    order_by: {nameFi: asc},
    offset: $offset,
    where: { documentState: { _nin: "DELETED" }}
  ) {
    id
    nameFi
  }
}
`;

export const sisuGetModuleIdQuery = (value) => {
  if (!value) {
    return null;
  }
  const payload = {
    query: getModuleId,
    variables: { module_id: value },
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

export const sisuSearchModuleIdQuery = (value, offset, fieldDeps) => {
  const searchTerm = value || '';
  const payload = {
    query: searchModuleId,
    variables: { search_term: '%' + value + '%', offset: offset },
    operationName: searchTerm ? 'withSearchTerm' : 'withoutSearchTerm',
  };
  return genericQuery(payload, '${id}: ${nameFi}');
};

// generic fuctions

const fillTemplate = (template, obj) => {
  return template.replace(/\$\{(\w+)\}/g, (match, variable) => {
    return obj[variable] !== undefined
      ? obj[variable] !== null
        ? obj[variable]
        : ''
      : match;
  });
};

const genericQuery = (payload, titleTemplate) => {
  const apiPath = window.env.RAZZLE_SISU_API_PATH;
  return new Promise((resolve, reject) => {
    superagent
      .post(apiPath)
      .send(payload)
      .then((resp) => {
        var error = null;
        if (resp.body?.data?.error) {
          error = resp.body.data.error;
        } else if (resp.body?.errors) {
          error = resp.body.errors;
        }

        if (error) {
          reject(error);
        } else {
          const results = [].concat(
            ...Object.keys(resp.body.data).map((k) => resp.body.data[k]),
          );

          const value = results.map((obj) => {
            return {
              value: obj.id,
              label: fillTemplate(titleTemplate, obj),
            };
          });
          resolve(value);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
