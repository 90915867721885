/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoEpicsAddon from 'volto-epics-addon';
import voltoS3WidgetAddon from 'volto-s3-widget-addon';
import ilpoEpicsExtensionsAddon from 'ilpo-epics-extensions-addon';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';

const addonsInfo = [{"modulePath":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.21.3","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"volto-epics-addon","version":"1.3.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/volto-epics-addon/src","packageJson":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/volto-epics-addon/package.json","addons":[]},{"name":"volto-s3-widget-addon","version":"0.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/volto-s3-widget-addon/src","packageJson":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/volto-s3-widget-addon/package.json","addons":[]},{"modulePath":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/src/addons/ilpo-epics-extensions-addon/src","packageJson":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/src/addons/ilpo-epics-extensions-addon/package.json","version":"1.0.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"ilpo-epics-extensions-addon","addons":[]},{"name":"@kitconcept/volto-blocks-grid","version":"5.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/@kitconcept/volto-blocks-grid/src","packageJson":"/nix/store/3gqqz0pfcbywxfvidvg4mhs3aib08q1p-ilpo-frontend/lib/ilpo-frontend/node_modules/@kitconcept/volto-blocks-grid/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoEpicsAddon, voltoS3WidgetAddon, ilpoEpicsExtensionsAddon, kitconceptvoltoBlocksGrid];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
