import React, { useEffect, useState } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@plone/volto/components';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { Container, Grid, Segment, Card } from 'semantic-ui-react';
import { PortalAccountCircleIcon } from '@package/icons';
import { defaultTitle } from '@package/helpers';
import { Helmet } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import PortalDefaultCardBg from './default_card_bg.svg';
import settingsIcon from './settings.svg';

const messages = defineMessages({
  title: {
    id: 'Dashboard',
    defaultMessage: 'Dashboard',
  },
  actionNeeded: {
    id: 'actionNeeded',
    defaultMessage: 'Action needed!',
  },
  loginfirst: {
    id: 'loginfirst',
    defaultMessage: 'You must log in first to see your courses.',
  },
  welcome: {
    id: 'Welcome',
    defaultMessage: 'Welcome',
  },
  personalDashboard: {
    id: 'personalDashboard',
    defaultMessage: 'This is your personal dashboard.',
  },
  profileSettings: {
    id: 'profileSettings',
    defaultMessage: 'Profile settings',
  },
  oma: {
    id: 'omaService',
    defaultMessage: 'Settings',
  },
  myCourses: {
    id: 'My Courses',
    defaultMessage: 'My Courses',
  },
  myModules: {
    id: 'myModules',
    defaultMessage: 'My Modules',
  },
  emptyList: {
    id: 'Empty list',
    defaultMessage: 'Empty list',
  },
  signUpForCourses: {
    id: 'Sign up for courses',
    defaultMessage: 'Sign up for courses',
  },
  noCoursesText: {
    id: 'noCoursesText',
    defaultMessage: "You don't have any courses yet.",
  },
  noCoursesLinkText: {
    id: 'noCoursesLinkText',
    defaultMessage: "Let's get started with course enrollment!",
  },
  noModulesText: {
    id: 'noModulesText',
    defaultMessage: "You don't have any modules yet. ",
  },
  viewAllLink: {
    id: 'viewAllLink',
    defaultMessage: 'View all',
  },
  noModulesLinkText: {
    id: 'noModulesLinkText',
    defaultMessage: "Let's get started with module enrollment!",
  },
  usernameText: {
    id: 'usernameText',
    defaultMessage: 'Your university username is: ',
  },
});

const ViewDashboard = (props) => {
  const intl = useIntl();
  const token = useSelector((state) => state.userSession.token);
  const items = useSelector((state) => state.search.items);
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [username, setUsername] = useState('');

  // fills `items` with content.
  useEffect(() => {
    if (token) {
      dispatch(
        searchContent(
          `/students/${encodeURIComponent(jwtDecode(token).sub).replace(
            '%',
            '-',
          )}`,
          {
            portal_type: ['Enrolment', 'Application'],
            fullobjects: true,
            metadata_fields: '_all',
          },
        ),
      );
      const name = jwtDecode(token).sub.split('@')[0];
      setUsername(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const courseItems = [];
    const moduleItems = [];
    items.forEach((item) => {
      if (item['@type'] === 'Enrolment') {
        courseItems.push(item);
      }
      if (item['@type'] === 'Application') {
        moduleItems.push(item);
      }
    });
    setCourses(courseItems);
    setModules(moduleItems);
  }, [items]);

  const renderState = (state) => {
    if (state === 'draft') {
      return (
        <p className="draft">{intl.formatMessage(messages.actionNeeded)}</p>
      );
    } else if (state === 'selected') {
      return (
        <p className="selected">{intl.formatMessage(messages.actionNeeded)}</p>
      );
    }
    return null;
  };

  return (
    <div className="dashboard-view">
      {token ? (
        <div className="dashboard">
          <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
          <div className="dashboard-header">
            <Container>
              <Grid stretched stackable className="dashboard-header-grip">
                <Grid.Row>
                  <Grid.Column width={2} textAlign="left">
                    <div className="icon-wrapper dashboard-profilelogo">
                      <Icon name={PortalAccountCircleIcon} color="#d9dfe6" />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Segment className="header-segment left">
                      <h4 style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage(messages.welcome)}
                        {', '}
                        {jwtDecode(token).fullname}
                      </h4>
                      <p>{intl.formatMessage(messages.personalDashboard)}</p>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <div className="ps-button">
                      <a
                        style={{ float: 'right' }}
                        href="https://account.jyu.fi"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage(messages.oma)}
                        <img src={settingsIcon} alt="Settings Icon" />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          <Container>
            <p>
              {intl.formatMessage(messages.usernameText)}
              <strong>{username}</strong>
            </p>
          </Container>
          <div className="dashboard-content">
            <Container className="container-courses">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column className="courses-row-header">
                    <h3 style={{ fontWeight: 'bold' }}>
                      {intl.formatMessage(messages.myCourses)}
                    </h3>

                    {courses.length > 3 && (
                      <div className="more-courses-container">
                        <p>
                          <a href={`/${currentLang}/mycourses`}>
                            {intl.formatMessage(messages.viewAllLink)}
                          </a>
                        </p>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="course-cards-list-template">
                      <Grid stackable>
                        {courses.length === 0 ? (
                          <div className="no-course-container">
                            <p>
                              {intl.formatMessage(messages.noCoursesText)}{' '}
                              {currentLang === 'en' ? (
                                <Link to="/en/courses">
                                  {intl.formatMessage(
                                    messages.noCoursesLinkText,
                                  )}
                                </Link>
                              ) : (
                                <Link to="/fi/courses">
                                  {intl.formatMessage(
                                    messages.noCoursesLinkText,
                                  )}
                                </Link>
                              )}
                            </p>
                          </div>
                        ) : (
                          courses.slice(0, 3).map((item, index) => (
                            <Grid.Column
                              stretched
                              tablet={6}
                              computer={4}
                              widescreen={4}
                              key={item['UID']}
                            >
                              <Card
                                fluid
                                as={Link}
                                to={flattenToAppURL(item?.course?.['@id'])}
                              >
                                <div className="course-cards-header">
                                  <div
                                    className="course-cards-image"
                                    style={{
                                      backgroundImage: `url(${
                                        flattenToAppURL(
                                          item.image?.scales.large.download ||
                                            '',
                                        ) || PortalDefaultCardBg
                                      })`,
                                    }}
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1440 320"
                                  >
                                    <path
                                      fill="#F6F7F7"
                                      fillOpacity="1"
                                      d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                                    ></path>
                                  </svg>
                                </div>
                                <Card.Content>
                                  <div className="course-cards-title">
                                    {item?.course?.title}
                                  </div>
                                </Card.Content>
                                <Card.Content extra>
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column className="courses-card-meta"></Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Card.Content>
                              </Card>
                            </Grid.Column>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
            <Container className="container-modules">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column className="modules-row-header">
                    <h3 style={{ fontWeight: 'bold' }}>
                      {intl.formatMessage(messages.myModules)}
                    </h3>
                    {modules.length > 3 && (
                      <div className="more-modules-container">
                        <p>
                          <a href={`/${currentLang}/mymodules`}>
                            {intl.formatMessage(messages.viewAllLink)}
                          </a>
                        </p>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="module-cards-list-template">
                      <Grid stackable>
                        {modules.length === 0 ? (
                          <div className="no-module-container">
                            <p>
                              {intl.formatMessage(messages.noModulesText)}{' '}
                              {currentLang === 'en' ? (
                                <Link to="/en/modules">
                                  {intl.formatMessage(
                                    messages.noModulesLinkText,
                                  )}
                                </Link>
                              ) : (
                                <Link to="/fi/modules">
                                  {intl.formatMessage(
                                    messages.noModulesLinkText,
                                  )}
                                </Link>
                              )}
                            </p>
                          </div>
                        ) : (
                          modules.slice(0, 3).map((item, index) => (
                            <Grid.Column
                              stretched
                              tablet={6}
                              computer={4}
                              widescreen={4}
                              key={item['UID']}
                            >
                              <Card
                                fluid
                                as={Link}
                                to={item?.['@id']}
                                className={'state-' + item?.['review_state']}
                              >
                                <div className="module-cards-header">
                                  <div
                                    className="module-cards-image"
                                    style={{
                                      backgroundImage: `url(${
                                        flattenToAppURL(
                                          item.image?.scales.large.download ||
                                            '',
                                        ) || PortalDefaultCardBg
                                      })`,
                                    }}
                                  >
                                    {renderState(item?.['review_state'])}
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1440 320"
                                  >
                                    <path
                                      fill="#F6F7F7"
                                      fillOpacity="1"
                                      d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                                    ></path>
                                  </svg>
                                </div>
                                <Card.Content>
                                  <div className="module-cards-title">
                                    {item?.module?.title || ''}
                                  </div>
                                </Card.Content>
                                <Card.Content extra>
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column className="module-cards-meta"></Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Card.Content>
                              </Card>
                            </Grid.Column>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        </div>
      ) : (
        <Container>
          <div className="not-logged-in-dashboard">
            <h4> {intl.formatMessage(messages.loginfirst)} </h4>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ViewDashboard;
