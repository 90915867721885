import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Segment,
  Step,
  Table,
} from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import {
  PortalBadgeIcon,
  PortalContinueIcon,
  PortalCourseIcon,
  PortalSignInIcon,
  PortalStarIcon,
  VoltoCartIcon,
  VoltoContentListing,
} from '@package/icons';
import { TASK_CHECKOUT, TASK_PROCEED_TO_PAY } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'Registration',
    defaultMessage: 'Registration',
  },
  yourorder: {
    id: 'Your order',
    defaultMessage: 'Your order:',
  },
  checkout: {
    id: 'Checkout',
    defaultMessage: 'Checkout',
  },
  continue: {
    id: 'Continue',
    defaultMessage: 'Continue',
  },
  voucherText: {
    id: 'voucherText',
    defaultMessage:
      'If you have a voucher or a promotion code, please enter it now and press Redeem',
  },
  enterPromotionCode: {
    id: 'enterPromotionCode',
    defaultMessage: 'Enter promotion code here',
  },
  redeem: {
    id: 'Redeem',
    defaultMessage: 'Redeem',
  },
});

const CheckoutPage = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [voucherCode, setVoucherCode] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productCredits, setProductCredits] = useState('');
  const task = useTask(TASK_CHECKOUT, TASK_PROCEED_TO_PAY);

  useEffect(() => {
    if (task?.variables?.course?.title) {
      setProductTitle(task.variables.course.title);
    } else if (task?.variables?.module?.title) {
      setProductTitle(task.variables.module.title);
    }

    if (task?.variables?.course?.price) {
      setProductPrice(task.variables.course.price);
    } else if (task?.variables?.module?.price) {
      setProductPrice(task.variables.module.price);
    }

    if (task?.variables?.course?.credits) {
      setProductCredits(task.variables.course.credits);
    }

    if (task) {
      setLoading(false);
    }
    if (
      task?.taskDefinitionKey === TASK_PROCEED_TO_PAY &&
      task?.completed === false &&
      task?.variables?.paymentAddress
    ) {
      window.location = task.variables.paymentAddress;
    }
  }, [task]);

  return (
    <div id="page-signup">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Container>
        <div>
          <Step.Group className="signup-steps" fluid size="mini" unstackable>
            <Step completed>
              <Icon name={PortalSignInIcon} />
            </Step>
            <Step completed>
              <Icon name={VoltoContentListing} />
            </Step>
            <Step active>
              <Icon name={VoltoCartIcon} />
            </Step>
            <Step>
              <Icon name={PortalStarIcon} />
            </Step>
          </Step.Group>
        </div>
        <div>
          <h2
            style={{
              fontFamily: 'Aleo',
              fontSize: '2.7em',
              textAlign: 'center',
              marginTop: '2em',
              marginBottom: '1.5em',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage(messages.checkout)}
          </h2>
          <div className="page-signup-content">
            <div className="content-header">
              <h3>{intl.formatMessage(messages.yourorder)}</h3>
            </div>
            <Segment basic loading={loading}>
              <Table className="order-table">
                <Table.Body>
                  <Table.Row style={{ boxShadow: 'none !important' }}>
                    <Table.Cell className="product-title-cell" width={8}>
                      <div className="title-wrapper">
                        <Icon name={PortalCourseIcon} size="36px" />
                        <span>{productTitle ?? 'n/a'}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="product-price-cell">
                      <div>
                        <span>{productPrice ?? 'n/a'} €</span>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#6C7979',
                          }}
                        >
                          {productCredits ? productCredits.toUpperCase() : ''}
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="button-cell">
                      <Button
                        icon
                        labelPosition="right"
                        onClick={async (e) => {
                          setLoading(true);
                          await completeTask(TASK_CHECKOUT, {
                            voucherCode: null,
                          });
                        }}
                      >
                        {intl.formatMessage(messages.continue)}
                        <Icon
                          className="center middle"
                          name={PortalContinueIcon}
                          size="1em"
                          fill="white"
                        />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Divider />
            <Grid
              stretched
              stackable
              textAlign="center"
              style={{
                marginTop: '1em',
                backgroundColor: '#f6f7f7',
                borderRadius: '8px',
                padding: '1em 0',
              }}
            >
              <Grid.Row verticalAlign="middle" columns="equal">
                <Grid.Column width={1} textAlign="center">
                  <Icon name={PortalBadgeIcon} />
                </Grid.Column>
                <Grid.Column width={7}>
                  {intl.formatMessage(messages.voucherText)}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Form loading={loading}>
                    <Form.Input
                      fluid
                      type="text"
                      onChange={(e) => {
                        setVoucherCode(e.target.value);
                      }}
                      placeholder={intl.formatMessage(
                        messages.enterPromotionCode,
                      )}
                    />
                    <Form.Button
                      className="promotion-button"
                      content={intl.formatMessage(messages.redeem)}
                      disabled={!voucherCode}
                      onClick={() => {
                        setLoading(true);
                        completeTask(TASK_CHECKOUT, {
                          voucherCode,
                        });
                      }}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutPage;
